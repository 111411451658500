import axios from 'axios';
import { useEffect, useState } from 'react';

import { ReactComponent as ChevronLeftIcon } from '../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../assets/icons/chevron-right.svg';

import { WEBSERVERPORT, NOTICE_TOKEN_NAME } from '../config';
import { setCookie, getCookie } from '../lib/cookie';
import { useTranslation } from 'react-i18next';
import useLang from '../lib/hook/useLang';
import { SpineRenderCommon } from '../util/SpineRenderCommon';
import { env } from '@/env';
import * as config from '../config';

export default function Notice() {
  const { i18n } = useTranslation();
  const { lngT, currentLang, isExists } = useLang('notice:maintenance');
  const [notices, setNotices] = useState([]);
  const [isClosed, setClosed] = useState(true);
  const [focusedNoticeIdx, setFocusedNoticeIdx] = useState(0);

  const [isInspectingOpen, setInspectingOpen] = useState(false);

  const handleClickCloseNotice = notToday => () => {
    if (notToday) {
      const expires = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
      setCookie(NOTICE_TOKEN_NAME, 'true', {
        path: '/', // 모든 경로에 적용
        maxAge: 24 * 60 * 60, // 30일 유지
        ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
      });
    }
    const body = document.querySelector('body');
    body.style.overflowX = 'hidden';
    body.style.overflowY = 'scroll';
    setClosed(true);
  };

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/notice/inspection`);

        if (data.ok) {
          const isInspecting = data.isInspecting;
          setInspectingOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    const closeForToday = getCookie(NOTICE_TOKEN_NAME);
    if (closeForToday !== 'true') {
      const body = document.querySelector('body');
      body.style.overflow = 'hidden';
      setClosed(false);
      return;
    } else {
      const body = document.querySelector('body');
      body.style.overflowX = 'hidden';
      body.style.overflowY = 'scroll';
      setClosed(true);
    }

    // (async function () {
    //   try {
    //     const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/notice`);

    //     if (data.notices.length) {
    //       const body = document.querySelector('body');
    //       body.style.overflow = 'hidden';
    //     }

    //     setNotices(data.notices);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // })();
  }, []);

  const isRacing = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING);

  let uselang = currentLang;
  if (!isExists) uselang = 'en';

  if (isClosed) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-[99] flex justify-center items-center bg-black/50 px-[4px] flex-col space-x-0 T:flex-row T:space-x-5 space-y-5 T:space-y-0">
      {isInspectingOpen ? (
        <div className="relative flex w-full min-[500px]:w-[480px] h-[530px] border border-white/60 bg-black rounded-2xl overflow-hidden px-[50px] pt-[45px] pb-[35px]">
          <img
            src="/images/icons/close.svg"
            alt=""
            className="absolute top-[14px] right-[18px] cursor-pointer w-[30px]"
            onClick={() => setInspectingOpen(false)}
          />
          <div className="flex flex-col w-full overflow-y-scroll">
            <img className="mb-[48px]" src="/images/logo.png" width={130} height={24} alt="logo" />
            <img className="absolute top-0 right-[115px]" src="/images/inspectionNoticePoligon_1.png" alt="polygon1" />
            <img className="absolute bottom-[30px] left-0" src="/images/inspectionNoticePoligon_2.png" alt="polygon1" />
            <img className="absolute top-[50px] right-0" src="/images/inspectionNoticePoligon_3.png" alt="polygon1" />
            <div className="flex flex-col font-oswald text-[#860099] text-[40px] leading-[50px] mb-[24px]">
              <span>{lngT('title:1')}:</span>
              <span>3-6 AM</span>
              <span>{lngT('title:2')}</span>
            </div>
            <div className="text-[16px] leading-[24px] text-white">
              <span className="font-[300] text-white/80 pr-2">{lngT('content:1')}</span>
              {lngT('content:2')}
              <span className="font-[300] text-white/80 pl-2">{lngT('content:3')}</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-col w-[80%] max-w-[400px] ${
            isRacing ? 'D:max-w-[400px] D:w-[400px]' : 'D:max-w-[800px] D:w-[800px]'
          } T:w-[400px]`}
        >
          {isRacing ? (
            <div className="flex flex-col D:flex-row">
              {/* <img
                src={`/images/popup/${isRacing ? 'racing' : 'powerball'}_a_${uselang}.png`}
                className="w-full T:w-[400px] D:w-[400px] D:h-[400px]"
                alt="poup"
              /> */}
              <img
                src={`/images/popup/racingB_${uselang}.png`}
                className="w-full T:w-[400px] D:w-[400px] D:h-[400px]"
                alt="poup"
              />
            </div>
          ) : (
            <div className="flex flex-col D:flex-row">
              <img
                src={`/images/popup/powerball_a_${uselang}.png`}
                className="w-full T:w-[400px] D:w-[400px] D:h-[400px]"
                alt="poup"
              />
              <img
                src={`/images/popup/powerball_b_${uselang}.png`}
                className="w-full T:w-[400px] D:w-[400px] D:h-[400px]"
                alt="poup"
              />
            </div>
          )}
          <div className="flex flex-row w-full justify-between bg-black/90 px-3 py-2 rounded-b-lg">
            <button
              className="text-white text-sm cursor-pointer hover:opacity-70"
              onClick={handleClickCloseNotice(true)}
            >
              close for today!
            </button>
            <img
              src="/images/icons/close.svg"
              alt=""
              className="cursor-pointer w-[30px]"
              onClick={handleClickCloseNotice()}
            />
          </div>
        </div>
      )}
    </div>
  );
}
